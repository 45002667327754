import React from 'react';


export default () => {
    return (

        <section className="section-card">
            <h1 className="coming-soon" style={{ marginLeft: "2rem" }}>
                COMING <br />SOON!
                </h1>
        </section>

    )
}